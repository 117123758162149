.resume__block {
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 0 0 20px 0;
}

@media screen and (max-width: 650px) {
  .resume__block {
    grid-template-columns: none;
    gap: 20px;
    margin: 0 0 50px 0;
  }
}