.resume {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  float: right;
  font-size: 16px;
}

@media screen and (max-width: 1000px) {
  .resume {
    display: block;
    width: 100%;
  }
}
